.print-page-footer {
    position: fixed;
    bottom: 0;
    width: 100%;
    z-index: 2222;
}

.print-page-header {
    position: fixed;
    width: 100%;
    z-index: 2222;
}

.print-page {
    page-break-before: always;
}

@page {
    margin: 20mm;
}

@media screen {
    .print-page-header {
        display: none;
    }
    .print-page-footer {
        display: none;
    }

    thead {
        display: table-header-group;
    }
    tfoot {
        display: table-footer-group;
    }
}

@media print {
    thead {
        display: table-header-group;
    }
    tfoot {
        display: table-footer-group;
    }

    button {
        display: none;
    }

    body {
        margin: 0;
    }
    .mobile-page {
            height:unset;
    }
  
}
