/* Tooltip container */
.tooltip2 {
  position: relative;
  display: inline-block;
  /*border-bottom: 1px dotted black;*/ /* If you want dots under the hoverable text */
}

/* Tooltip text */
.tooltip2 .tooltiptext2 {
  visibility: hidden;
  width: 120px;
  color: #fff;
  text-align: center;
  padding: 5px 0 !important;
  border-radius: 6px;
  font-size: 12px !important;
 
  /* Position the tooltip text - see examples below! */
  position: absolute;
  z-index: 1;
}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltip2:hover .tooltiptext2 {
  visibility: visible;
}