.rt-sidebar {
  background-color: $react-timelines-sidebar-background-color;
  border-right: 1px solid #ccc;
  //box-shadow: 10px 0 10px -5px rgba(12, 12, 12, 0.1);
}

.rt-sidebar__header {
  background-color: $react-timelines-sidebar-background-color;
}

.rt-sidebar__header.rt-is-sticky {
  position: fixed;
  top: 0;
  z-index: 2;
  direction: rtl;
  margin-left: ($react-timelines-sidebar-width - $react-timelines-sidebar-closed-offset);

  @media (min-width: $react-timelines-auto-open-breakpoint) {
    margin-left: 0;
    direction: ltr;
  }
}

.rt-layout.rt-is-open .rt-sidebar__header.rt-is-sticky {
  margin-left: 0;
  direction: ltr;
}

.rt-sidebar__body {}
