@font-face {
  font-family: 'icomoon';
  src:  url('fonts/icomoon.eot?8fwvk9');
  src:  url('fonts/icomoon.eot?8fwvk9#iefix') format('embedded-opentype'),
    url('fonts/icomoon.ttf?8fwvk9') format('truetype'),
    url('fonts/icomoon.woff?8fwvk9') format('woff'),
    url('fonts/icomoon.svg?8fwvk9#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}

.icon {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-icon-sos:before {
  content: "\e9dd";
}
.icon-icons8-smoke-detector-100:before {
  content: "\e99f";
}
.icon-icons8-walking-96-2:before {
  content: "\e9c1";
}
.icon-Bathtub:before {
  content: "\e9ab";
}
.icon-BedLamp:before {
  content: "\e9ac";
}
.icon-Bedroom:before {
  content: "\e9b4";
}
.icon-Bell:before {
  content: "\e9b5";
}
.icon-Beverage:before {
  content: "\e9b6";
}
.icon-Building:before {
  content: "\e9b7";
}
.icon-ChristmasTree:before {
  content: "\e9b8";
}
.icon-DeskLamp:before {
  content: "\e9b9";
}
.icon-Dining:before {
  content: "\e9ba";
}
.icon-Door1:before {
  content: "\e9bb";
}
.icon-Door2:before {
  content: "\e9bc";
}
.icon-Electric:before {
  content: "\e9bd";
}
.icon-Fridge:before {
  content: "\e9be";
}
.icon-Gamepad:before {
  content: "\e9bf";
}
.icon-Garage:before {
  content: "\e9c0";
}
.icon-Home:before {
  content: "\e9c2";
}
.icon-Home2:before {
  content: "\e9c3";
}
.icon-if_Streamline-18_185038:before {
  content: "\e9c4";
}
.icon-if_Streamline-75_185095:before {
  content: "\e9c5";
}
.icon-Kitchen:before {
  content: "\e9c6";
}
.icon-LightBulb:before {
  content: "\e9c7";
}
.icon-Locked:before {
  content: "\e9c8";
}
.icon-Parking:before {
  content: "\e9c9";
}
.icon-Parking2:before {
  content: "\e9ca";
}
.icon-Parking3:before {
  content: "\e9cb";
}
.icon-Phone:before {
  content: "\e9cc";
}
.icon-Plant:before {
  content: "\e9cd";
}
.icon-Pool:before {
  content: "\e9ce";
}
.icon-Remote:before {
  content: "\e9cf";
}
.icon-Remote2:before {
  content: "\e9d0";
}
.icon-Settings:before {
  content: "\e9d1";
}
.icon-Shower:before {
  content: "\e9d2";
}
.icon-Shower2:before {
  content: "\e9d3";
}
.icon-Sofa1:before {
  content: "\e9d4";
}
.icon-Sofa21:before {
  content: "\e9d5";
}
.icon-Speakers:before {
  content: "\e9d6";
}
.icon-Sprinkler3:before {
  content: "\e9d7";
}
.icon-Surveillance:before {
  content: "\e9d8";
}
.icon-Tag:before {
  content: "\e9d9";
}
.icon-Tag2:before {
  content: "\e9da";
}
.icon-Unlocked:before {
  content: "\e9db";
}
.icon-WashingMachine:before {
  content: "\e9dc";
}
.icon-Window:before {
  content: "\e9de";
}
.icon-Workplace:before {
  content: "\e9df";
}
.icon-Sofa2:before {
  content: "\e9e0";
}
.icon-link:before {
  content: "\e900";
}
.icon-tablet:before {
  content: "\e901";
}
.icon-window:before {
  content: "\e902";
}
.icon-monitor:before {
  content: "\e903";
}
.icon-ipod:before {
  content: "\e904";
}
.icon-tv:before {
  content: "\e905";
}
.icon-camera:before {
  content: "\e906";
}
.icon-camera2:before {
  content: "\e907";
}
.icon-film:before {
  content: "\e908";
}
.icon-film2:before {
  content: "\e909";
}
.icon-microphone:before {
  content: "\e90a";
}
.icon-microphone2:before {
  content: "\e90b";
}
.icon-drink:before {
  content: "\e90c";
}
.icon-drink2:before {
  content: "\e90d";
}
.icon-coffee:before {
  content: "\e90e";
}
.icon-mug:before {
  content: "\e90f";
}
.icon-android:before {
  content: "\e910";
}
.icon-chronometer:before {
  content: "\e911";
}
.icon-headphones:before {
  content: "\e912";
}
.icon-folder:before {
  content: "\e913";
}
.icon-gamepad:before {
  content: "\e914";
}
.icon-alarm:before {
  content: "\e915";
}
.icon-alarm-cancel:before {
  content: "\e916";
}
.icon-phone:before {
  content: "\e917";
}
.icon-phone2:before {
  content: "\e918";
}
.icon-open:before {
  content: "\e919";
}
.icon-trashcan:before {
  content: "\e91a";
}
.icon-eightball:before {
  content: "\e91b";
}
.icon-soccer:before {
  content: "\e91c";
}
.icon-3dglasses:before {
  content: "\e91d";
}
.icon-microwave:before {
  content: "\e91e";
}
.icon-mouse:before {
  content: "\e91f";
}
.icon-smiley:before {
  content: "\e920";
}
.icon-sad:before {
  content: "\e921";
}
.icon-hand:before {
  content: "\e922";
}
.icon-radio:before {
  content: "\e923";
}
.icon-key:before {
  content: "\e924";
}
.icon-cord:before {
  content: "\e925";
}
.icon-locked:before {
  content: "\e926";
}
.icon-unlocked:before {
  content: "\e927";
}
.icon-locked2:before {
  content: "\e928";
}
.icon-unlocked2:before {
  content: "\e929";
}
.icon-lamp:before {
  content: "\e92a";
}
.icon-lamp2:before {
  content: "\e92b";
}
.icon-umbrella:before {
  content: "\e92c";
}
.icon-streetlight:before {
  content: "\e92d";
}
.icon-megaphone:before {
  content: "\e92e";
}
.icon-megaphone2:before {
  content: "\e92f";
}
.icon-thermometer:before {
  content: "\e930";
}
.icon-lamp3:before {
  content: "\e931";
}
.icon-lamp4:before {
  content: "\e932";
}
.icon-lamp5:before {
  content: "\e933";
}
.icon-list:before {
  content: "\e934";
}
.icon-list2:before {
  content: "\e935";
}
.icon-layout:before {
  content: "\e936";
}
.icon-layout2:before {
  content: "\e937";
}
.icon-layout3:before {
  content: "\e938";
}
.icon-layout4:before {
  content: "\e939";
}
.icon-tools:before {
  content: "\e93a";
}
.icon-screwdriver:before {
  content: "\e93b";
}
.icon-paint:before {
  content: "\e93c";
}
.icon-volume:before {
  content: "\e93d";
}
.icon-volume2:before {
  content: "\e93e";
}
.icon-volume3:before {
  content: "\e93f";
}
.icon-equalizer:before {
  content: "\e940";
}
.icon-calculator:before {
  content: "\e941";
}
.icon-library:before {
  content: "\e942";
}
.icon-auction:before {
  content: "\e943";
}
.icon-justice:before {
  content: "\e944";
}
.icon-hourglass:before {
  content: "\e945";
}
.icon-pencil:before {
  content: "\e946";
}
.icon-scissors:before {
  content: "\e947";
}
.icon-reply:before {
  content: "\e948";
}
.icon-forward:before {
  content: "\e949";
}
.icon-retweet:before {
  content: "\e94a";
}
.icon-shuffle:before {
  content: "\e94b";
}
.icon-envelope:before {
  content: "\e94c";
}
.icon-music:before {
  content: "\e94d";
}
.icon-presentation:before {
  content: "\e94e";
}
.icon-piano:before {
  content: "\e94f";
}
.icon-rain:before {
  content: "\e950";
}
.icon-snow:before {
  content: "\e951";
}
.icon-lightning:before {
  content: "\e952";
}
.icon-cloudy:before {
  content: "\e953";
}
.icon-cloudy2:before {
  content: "\e954";
}
.icon-car:before {
  content: "\e955";
}
.icon-bike:before {
  content: "\e956";
}
.icon-truck:before {
  content: "\e957";
}
.icon-bus:before {
  content: "\e958";
}
.icon-bike2:before {
  content: "\e959";
}
.icon-plane:before {
  content: "\e95a";
}
.icon-rocket:before {
  content: "\e95b";
}
.icon-expand:before {
  content: "\e95c";
}
.icon-collapse:before {
  content: "\e95d";
}
.icon-badge:before {
  content: "\e95e";
}
.icon-microphone3:before {
  content: "\e95f";
}
.icon-blocked:before {
  content: "\e960";
}
.icon-stop1:before {
  content: "\e961";
}
.icon-keyboard:before {
  content: "\e962";
}
.icon-radio2:before {
  content: "\e963";
}
.icon-printer:before {
  content: "\e964";
}
.icon-shipping:before {
  content: "\e965";
}
.icon-yingyang:before {
  content: "\e966";
}
.icon-magnet:before {
  content: "\e967";
}
.icon-usb:before {
  content: "\e968";
}
.icon-clipboard:before {
  content: "\e969";
}
.icon-switch:before {
  content: "\e96a";
}
.icon-laptop:before {
  content: "\e001";
}
.icon-desktop:before {
  content: "\e002";
}
.icon-tablet2:before {
  content: "\e003";
}
.icon-phone3:before {
  content: "\e004";
}
.icon-video:before {
  content: "\e011";
}
.icon-camera3:before {
  content: "\e012";
}
.icon-key2:before {
  content: "\e02c";
}
.icon-lightbulb:before {
  content: "\e030";
}
.icon-mic:before {
  content: "\e03a";
}
.icon-happy:before {
  content: "\e05b";
}
.icon-sad2:before {
  content: "\e05c";
}
.icon-lock:before {
  content: "\e96b";
}
.icon-lock-open:before {
  content: "\e96c";
}
.icon-lock-open2:before {
  content: "\e96d";
}
.icon-lock-stripes:before {
  content: "\e96e";
}
.icon-lock-rounded:before {
  content: "\e96f";
}
.icon-lock-rounded-open:before {
  content: "\e970";
}
.icon-lock-rounded-open2:before {
  content: "\e971";
}
.icon-combination-lock:before {
  content: "\e972";
}
.icon-soccer-ball:before {
  content: "\e973";
}
.icon-soccer-court:before {
  content: "\e974";
}
.icon-weights:before {
  content: "\e975";
}
.icon-basketball-hoop:before {
  content: "\e976";
}
.icon-cloud:before {
  content: "\e977";
}
.icon-cloud-sun:before {
  content: "\e978";
}
.icon-cloud-moon:before {
  content: "\e979";
}
.icon-cloud-rain:before {
  content: "\e97a";
}
.icon-cloud-sun-rain:before {
  content: "\e97b";
}
.icon-cloud-snow:before {
  content: "\e97c";
}
.icon-cloud-sun-snow:before {
  content: "\e97d";
}
.icon-cloud-lightning:before {
  content: "\e97e";
}
.icon-cloud-wind:before {
  content: "\e97f";
}
.icon-cloud-raindrops:before {
  content: "\e980";
}
.icon-cloud-snowflakes:before {
  content: "\e981";
}
.icon-sun:before {
  content: "\e982";
}
.icon-umbrella2:before {
  content: "\e983";
}
.icon-sunglasses:before {
  content: "\e984";
}
.icon-thermometer2:before {
  content: "\e985";
}
.icon-thermometer-low:before {
  content: "\e986";
}
.icon-thermometer-quarter:before {
  content: "\e987";
}
.icon-thermometer-half:before {
  content: "\e988";
}
.icon-sound:before {
  content: "\e989";
}
.icon-key3:before {
  content: "\e98a";
}
.icon-lock2:before {
  content: "\e98b";
}
.icon-display:before {
  content: "\e98c";
}
.icon-clock:before {
  content: "\e98d";
}
.icon-music2:before {
  content: "\e98e";
}
.icon-megaphone3:before {
  content: "\e98f";
}
.icon-food:before {
  content: "\e990";
}
.icon-fire:before {
  content: "\e991";
}
.icon-shop:before {
  content: "\e992";
}
.icon-account_balance:before {
  content: "\e84f";
}
.icon-goat:before {
  content: "\e993";
}
.icon-headset:before {
  content: "\e310";
}
.icon-headset_mic:before {
  content: "\e311";
}
.icon-local_hotel:before {
  content: "\e549";
}
.icon-notifications:before {
  content: "\e7f4";
}
.icon-notifications_active:before {
  content: "\e7f7";
}
.icon-notifications_none:before {
  content: "\e7f5";
}
.icon-notifications_off:before {
  content: "\e7f6";
}
.icon-notifications_paused:before {
  content: "\e7f8";
}
.icon-personal_video:before {
  content: "\e63b";
}
.icon-phone_android:before {
  content: "\e324";
}
.icon-washMachine2:before {
  content: "\e9b3";
}
.icon-microvawe4:before {
  content: "\e995";
}
.icon-blender:before {
  content: "\e996";
}
.icon-sprinkle:before {
  content: "\e997";
}
.icon-coolingFan:before {
  content: "\e998";
}
.icon-iron:before {
  content: "\e999";
}
.icon-hairdryer:before {
  content: "\e99a";
}
.icon-vacuumCleaner:before {
  content: "\e99b";
}
.icon-fridge2:before {
  content: "\e99c";
}
.icon-fridge1:before {
  content: "\e99d";
}
.icon-coffeeMachine:before {
  content: "\e99e";
}
.icon-soundSystem:before {
  content: "\e9a0";
}
.icon-microwave1:before {
  content: "\e9a1";
}
.icon-cooker2:before {
  content: "\e9a2";
}
.icon-cooker:before {
  content: "\e9a3";
}
.icon-livingRoomFurniture3:before {
  content: "\e9a4";
}
.icon-livingRoomFurniture:before {
  content: "\e9a5";
}
.icon-airConditioner2:before {
  content: "\e9a6";
}
.icon-kitchen:before {
  content: "\e9a7";
}
.icon-workingRoom:before {
  content: "\e9a8";
}
.icon-bed2:before {
  content: "\e9a9";
}
.icon-bed:before {
  content: "\e9aa";
}
.icon-sos:before {
  content: "\e9ad";
}
.icon-door:before {
  content: "\e9ae";
}
.icon-window1:before {
  content: "\e9af";
}
.icon-curtain:before {
  content: "\e9b0";
}
.icon-opened-window-door-of-glasses:before {
  content: "\e9b1";
}
.icon-open-exit-door:before {
  content: "\e9b2";
}
.icon-concierge-bell-solid:before {
  content: "\e994";
}
.icon-play2:before {
  content: "\ea15";
}
.icon-pause:before {
  content: "\ea16";
}
.icon-stop:before {
  content: "\ea17";
}
.icon-play3:before {
  content: "\ea1c";
}
.icon-pause2:before {
  content: "\ea1d";
}
.icon-stop2:before {
  content: "\ea1e";
}
.icon-backward2:before {
  content: "\ea1f";
}
.icon-forward3:before {
  content: "\ea20";
}
.icon-first:before {
  content: "\ea21";
}
.icon-last:before {
  content: "\ea22";
}
.icon-previous2:before {
  content: "\ea23";
}
.icon-next2:before {
  content: "\ea24";
}
.icon-eject:before {
  content: "\ea25";
}
.icon-volume-high:before {
  content: "\ea26";
}
.icon-volume-medium:before {
  content: "\ea27";
}
.icon-volume-low:before {
  content: "\ea28";
}
.icon-volume-mute:before {
  content: "\ea29";
}
.icon-volume-mute2:before {
  content: "\ea2a";
}
.icon-volume-increase:before {
  content: "\ea2b";
}
.icon-volume-decrease:before {
  content: "\ea2c";
}
