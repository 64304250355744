/* 
@font-face {
  font-family: WorkSans;
  font-style: normal;
  font-weight: 400;
  src: url('./fonts/work-sans-v18-latin-regular.eot');  
  src: local(''),
       url('./fonts/work-sans-v18-latin-regular.eot?#iefix') format('embedded-opentype'), 
       url('./fonts/work-sans-v18-latin-regular.woff2') format('woff2'),  
       url('./fonts/work-sans-v18-latin-regular.woff') format('woff'),  
       url('./fonts/work-sans-v18-latin-regular.ttf') format('truetype'),  
       url('./fonts/work-sans-v18-latin-regular.svg#WorkSans') format('svg');  
} */


.pointer{
    cursor: pointer !important;
}

@media print{
  footer, .pageBr { page-break-before: always;  }
  .pageHeaderScreen { display: none;  }
  .no-print, .no-printe * {
    display: none !important;
  }
  
  /* .scrollX {
    overflow-x:unset !important;
} */

.reception-background {
    position: unset !important;
    overflow-x: unset !important;
    overflow-y: unset !important;
    /* overflow: auto !important; */
}
  .footer{
    position: fixed;
    bottom:0;
    left:0;
    right:0;
  }
  /* .konj{
    page-break-after: always !important; 
  } */

  .break-print-page{
    page-break-after: always !important; 

  }
  
}

/* .scrollX{
  overflow-x: scroll;
} */



.special-class-for-demo-and-test{
  background: linear-gradient(88deg,#3f4040,#205181) !important;
}

@media screen {
  .pageHeader {
    display: none;
  }
}

.rc-time-picker-panel{ /*z-index fix on time select  */
  z-index: 10001 !important;
}

.elipsis {
    white-space: nowrap;
  overflow: hidden;
    text-overflow: ellipsis;
}
.pimatico-card-link:hover{
	cursor: pointer;
	background-color: #f4f5f6;
	color: black;
}

.clear{ /*imaš clearfix u bootstrapu*/
	clear: both;
}
.main-content-margins{
	margin-top:66px;
}

.unselectable{
  -moz-user-select: -moz-none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -o-user-select: none;
  user-select: none;
}

.unEditable{
  pointer-events: none;

}

.hotel-background{
    border: 0;
    padding: 10px;
    top:56px;
    bottom:0px;
    left:0;
    position: fixed;
  /*  height: 100%;*/
    width: 100%;
    background: url("../img/hostel.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    overflow-y: auto;
}

.reception-background{
    border: 0;
    /*padding: 10px; */
    top:0;
    bottom:0px;
    left:0;
    position: fixed;
    width: 100%;
    /* background: url("../img/reception.jpg"); */
    background-repeat: no-repeat;
    background-size: cover;
    overflow-y: auto;
    overflow-x: hidden;
        
}
.reception-background-no-overflow{
  border: 0;
  /*padding: 10px; */
  top:0;
  bottom:0px;
  left:0;
  position: fixed;
  width: 100%;
  /* background: url("../img/reception.jpg"); */
  background-repeat: no-repeat;
  background-size: cover;
  overflow-x: hidden;
      
}

.docScann-bg-step1{
  background: url("../img/welcome.jpg");
  background-repeat: no-repeat;
  background-size: cover;
}

.docScann-bg-step2{
  background: url("../img/hotel-reception-bell_1.jpg");
  background-repeat: no-repeat;
  background-size: cover;
}

.docScann-bg-step3{
  background: url("../img/room.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.keys-background{
    border: 0;
    padding: 10px;
    top:56px;
    bottom:0px;
    left:0;
    position: fixed;
    width: 100%;
    background: url("../img/keys.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    overflow-y: auto; 
}

.settings-background{
    border: 0;
    padding: 10px;
    top:56px;
    bottom:0px;
    left:0;
    position: fixed;
    width: 100%;
    background: url("../img/settings.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    overflow-y: auto; 
}

.fiscalImage{
  background: url("../img/cash.png");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  height: 100px;
  width: 100px;
  align-items: center;
}

.cashRegistarImage{
  background: url("../img/money.png");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  height: 100px;
  width: 100px;
  align-items: center;
}

.reservationsImage{
  background: url("../img/reservations.png");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  height: 100px;
  width: 100px;
  align-items: center;
}

.centerText {
  border-radius: 5px;
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
  background-color: white;
  text-align: center;
  cursor: pointer;
}

.reports-background{
    border: 0;
    padding: 10px;
    top:56px;
    bottom:0px;
    left:0;
    position: fixed;
    width: 100%;
    background: url("../img/reports.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    overflow-y: auto; 
}

.doors-background{
    border: 0;
    padding: 10px;
    top:56px;
    bottom:0px;
    left:0;
    position: fixed;
    width: 100%;
    background: url("../img/doors.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    overflow-y: auto; 
}
.uppercase{
  text-transform: uppercase;
}
.blink-animation {
    animation: blinker 1s linear infinite;
  }
  
  @keyframes blinker {
    50% {
      opacity: 0;
    }
  }


.breadcrumbs {
    background-color: white;
    top:58px;
    width: 100%;
    position: relative;
    z-index: 9900;
}

.opacity-9{
  opacity: 0.9;
}

.textBold{
  font-weight: bold;
}


.navbar-gradient{
  background:linear-gradient(88deg,#04befe,#318be3);
}


.hover-background:hover {
    background-color: #f4f5f6 !important;
}

.hover-background-white:hover {
  background-color: white !important;
}

.hover-background-dark:hover {
  background-color: rgb(236, 236, 236) !important;
}
/* day picker hack*/

.DayPickerInput-OverlayWrapper{
    z-index: 100;
}


.weekDays-selector input {
  display: none!important;
}

.weekDays-selector input[type=checkbox] + label {
  display: inline-block;
  border-radius: 6px;
  background: hsl(0, 0%, 87%);
  height: 40px;
  width: 30px;
  margin-right: 10px;
  line-height: 40px;
  text-align: center;
  cursor: pointer;
}

.weekDays-selector input[type=checkbox]:checked + label {
  background: #2AD705;
  color: #ffffff;
}


.custom-small-button-reports{
  cursor: pointer;
  padding: 4px 4px;
  /* margin: 2px auto; */
  width: 80%;
  height: inherit;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-overflow: ellipsis;
  background-color: #04befe;
  border-radius: 5px;
  font-size: 0.8em
}
.custom-small-button-reports:hover{
  background-color: #039fd3;
}

.disable-element{
  pointer-events: none;  
  opacity: 0.8
}
.increase-decrease-button:hover{
  background-color: #039fd32a;
}

.select-none {
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}
#hover-toggle-menu:hover{
  background-color: #009df5 !important;
  color: white !important;
  cursor: pointer;
}

.hover-menu-item:hover{
  background-color: #0a82af !important;
}

.demo-test-hover-menu-item:hover{
  background-color: #5a5a5a !important;
}

.custom-animated {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}



@-webkit-keyframes fadeInRightCustom {
  from {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@keyframes fadeInRightCustom {
  from {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

.fadeInRightCustom {
  -webkit-animation-name: fadeInRightCustom;
  animation-name: fadeInRightCustom;
}


@-webkit-keyframes fadeOutLeftCustom {
  from {
    opacity: 1;
  }
  10%{
    width: 200px

  }
20%{
  width: 150px
    
  }
  30%{
    width: 100px
    
  }
  60%{
    width: 50px
    
  }
  80%{
    width: 20px
    
  }
  to {
    /* opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0); */
    width: 20px
  }
}

@keyframes fadeOutLeftCustom {
  0% {
    opacity:  200px
  }
  10%{
    width: 180px

  }
20%{
  width: 160px
    
  }
  30%{
    width: 140px
    
  }
  40%{
    width: 120px

  }
50%{
  width: 100px
    
  }
 
  60%{
    width: 80px
    
  }
  
  70%{
    width: 60px
    
  }
  
  80%{
    width: 40px
    
  }

  90%{
    width: 20px
    
  }
  100%{
    width: 20px

  }
  to {
    /* opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0); */
    width: 20px

  }
}

.fadeOutLeftCustom {
  -webkit-animation-name: fadeOutLeftCustom;
  animation-name: fadeOutLeftCustom;
}


@-webkit-keyframes fadeInLeftCustom {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@keyframes fadeInLeftCustom {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

.fadeInLeftCustom {
  -webkit-animation-name: fadeInLeftCustom;
  animation-name: fadeInLeftCustom;
}

.custom-animated.faster {
  -webkit-animation-duration: 500ms;
  animation-duration: 500ms;
}
.z-2000{
  z-index: 2000;
}

@media (max-width: 377px) {
  .margin-left-top{
    margin-top: .5rem;
  }
  }
  
@media (max-width: 503px) {
  .margin-top{
    margin-top: .5rem;
  }
}

@media (max-width: 334px) {
  .margin-top-left{
    margin-top: .5rem;
  }
  }

/*
.ui-options-selector input {
  display: none!important;
}

.ui-options-selector input[type=checkbox] + label {
  display: inline-block;
  border-radius: 6px;
  background: hsl(0, 0%, 87%);
  height: 38px;
  width: 210px;
  margin-right: 10px;
  line-height: 40px;
  text-align: center;
  cursor: pointer;
}

.ui-options-selector input[type=checkbox]:checked + label {
  background:linear-gradient(88deg,#318be3,#04befe);
  color: #ffffff;
}



*/

