.rt-element {
  $height: $react-timelines-track-height - 2 * $react-timelines-element-spacing;
   font-size:$tooltip-font-size;
  position: relative;
  height: $height;
  line-height: $height;
  background: #ccc;
  color: #fff;
  text-align: center;
  border-radius: 6px;

 // border: 1px solid #ccc;
}

.rt-element__content {
  padding: 0 10px;
  overflow: hidden;
  white-space: nowrap;
  //font-weight: bold;

  text-overflow: ellipsis;
}

.rt-element__tooltip {
 box-shadow: 3px 3px 0px rgba(0, 0, 0, 0.15);
  border: 1px solid #ccc;
  border-radius: 6px;
  position: absolute;
  bottom: 100%;
  left: 50%;
  z-index: 2;
  font-size:$tooltip-font-size;
  padding: 15px;
  line-height: 1.3;
  white-space: nowrap;
  text-align: left;
  background: $tooltip-background-color;
  color:  $react-timelines-text-color;
  transform: translateX(-50%) scale(0);
  pointer-events: none;

  &::before {

    $size: 6px;
    position: absolute;
    top: 100%;
    left: 50%;
    border-bottom: none;
    border-top: $size solid $react-timelines-text-color;
    border-right: $size solid transparent;
    border-left: $size solid transparent;
    transform: translateX(-50%);
    content: ' ';
  }
}

.rt-element:hover > .rt-element__tooltip,
.rt-element:focus > .rt-element__tooltip {
  $delay: 0.3s;
  transform: translateX(-50%) scale(1);
  transition: transform 0s $delay;
}

.rt-tracks>.tr-track:first-child .rt-element__tooltip {
  top: 28px !important;
  bottom: auto;

  &::before {

    $size: 6px;
    position: absolute;
    top: -5px;
    left: 50%;
    border-top: none;
    border-bottom: $size solid $react-timelines-text-color;
    border-right: $size solid transparent;
    border-left: $size solid transparent;
    transform: translateX(-50%);
    content: ' ';
  }
}