$primaryColor: #1068ec;
$primaryColor50: #dbe8fc;
$primaryColor100: #abcaf8;
$primaryColor500: #1068ec;
$sucessColor500: #17e15c;
$warningColor500: #fc8518;
$errorColor500: #ec1010;
$UI-900: #00112b;
$UI-50: #ffffff;
$primaryColor900: #002459;
$neutralColor700: #69778c;
$neutralColor500: #8f9db2;
$neutralColor300: #a3aebf;
$neutralColor100: #d8e0ec;
$neutralColor50: #f3f6fb;
$standardFontSize: 12px;
$sideMenuUserDataWrapperHeight: 100px;
$sideMenuBackgroundColor: $neutralColor50;
$sideMenuLogoHeight: 78px;
$primaryColor700: #0545a6;
$primaryColor300: #5895f2;

@mixin unselectable {
    -moz-user-select: -moz-none !important;
    -khtml-user-select: none !important;
    -webkit-user-select: none !important;
    -o-user-select: none !important;
    user-select: none !important;
}

@media only screen and (max-width: 600px) {
    .page-title {
        // padding-top: 0 !important;
        // padding-bottom: 0 !important;
    }
    .login-wrapper {
        flex: 1;
    }

    .report-link {
        height: auto !important;
    }

    .reports-menu .custom-list-header {
        min-width: auto !important;
    }

    .report-menu-links {
        display: flex;
        flex-direction: column;
    }

    .search-and-page-controls {
        margin-top: 8px;
    }
    .display-flex-mobile {
        display: flex !important;
    }
    .login-background {
        display: none;
    }

    .login-form-wrapper {
        background-color: white;
    }

    #myMainMenu {
        // position: absolute !important;
        right: 0;
    }

    #myMainMenu:not(.mobile-side-menu) {
        display: none;
    }

    .mobile-side-menu {
        bottom: 0px;
        top: 0px;
        z-index: 10000;
        position: fixed !important;
    }

    .dashboard-filters-wrapper .dashboard-filters {
        // display: none !important;
    }

    .mobile-page .dashboard-filters-wrapper {
        justify-content: start !important;
    }

    .dashboard-filters-wrapper {
        // display: none !important;
        height: auto !important;
        margin: 8px 0;
    }
    .toolbar-filters-icon-mobile {
        margin-right: 16px;
    }
    .mobile-toolbar-filters {
        padding: 24px 16px;
        position: absolute;
        right: 0;
        z-index: 1000;
        background: white;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border: 1px solid $neutralColor100;
        border-radius: 4px;
        margin-right: 16px;
        margin-top: 4px;
        .mobile-toolbar-select {
            margin-top: 12px;
            width: 100%;
            display: flex;
            justify-content: flex-start;
        }

        .mulitple-select-wrapper {
            margin-left: 0;
        }
        .toolbar-filters-mobile-header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;
            span {
                &:first-child {
                    font-weight: bold;
                }
            }
            i {
                font-size: $standardFontSize;
            }
        }
    }

    // .reservation-filters-wrapper{
    //     display: none !important;
    // }
    .reservation-filters-wrapper-mobile {
        position: relative;

        .availabilty-filters-mobile {
            position: absolute;
            z-index: 1000;
            background-color: white;
            width: 270px;
            padding: 24px 16px;
            border: 1px solid $neutralColor100;
            border-radius: 4px;
            margin-left: 16px;

            .availabilty-filters-mobile-header {
                display: flex;
                justify-content: space-between;
                align-items: center;
                span {
                    &:first-child {
                        font-weight: bold;
                    }
                }
                i {
                    font-size: $standardFontSize;
                }
            }

            .DayPickerInput-mobile {
                width: 114px !important;
            }
        }

        .availibility-filter-button {
            margin-top: 12px;
        }
        .reservation-filters-wrapper-mobile-button {
            // margin-left: 16px;
            margin-top: 30px;
        }
        .input {
            margin-top: 12px;
        }

        .DayPickerInput-wrapper {
            margin-top: 24px;
            display: flex;
            justify-content: space-between;
        }
    }
    .reservation-status-checkboxes-wrapper {
        min-height: 64px !important;
        // margin: 16px !important;
        .reservation-status-checkboxes {
            // display: none !important;
            display: flex;
            flex-wrap: wrap;
        }
    }

    #activity-sidebar {
        position: absolute !important;
        right: 0;
        z-index: 1000;
    }
    #activity-sidebar.hidden {
        display: none;
    }
    #sidebar {
        width: auto;
        display: none !important;
    }
    // .master-layout-mobile{
    //     #activity-sidebar{
    //         display: none !important;
    //     }
    // }
    #timeline-view {
        width: 100% !important;
    }

    .master-layout-clear {
        display: none;
    }
    .room-event-history .dashboard-filters {
        flex-direction: column;
    }
    .room-event-history .dashboard-filters > div {
        margin-top: 8px;
    }
    .mobile-page {
        padding: 14px 16px;

        .page-title {
            padding-top: 0 !important;
            // padding-bottom: 0 !important;
        }

        .button-link {
            text-decoration: underline;
            // align-self: flex-end;
            width: auto !important;
            padding: 6px 10px !important;
            height: 32px;
        }
        .overflowX-auto-mobile {
            overflow-x: auto;
        }
        .page-title-button {
            margin-right: 0;
            padding-top: 0;
        }

        .page-controls {
            margin-right: 0 !important;
        }
        .reservation-administration {
            flex-direction: column;
            align-items: flex-start;
        }
        .reservation-administration > div {
            margin-top: 4px;
        }

        //kasnije dodano
        display: flex;
        flex-direction: column;
        height: 100%;

        .title-with-options {
            display: flex;
            flex-direction: column;
        }

        .button-link {
            // padding: 0 !important;
        }
    }

    .household-buttons-group {
        display: flex;
        flex-wrap: wrap;

        button {
            margin: 2px;
        }
    }

    .custom-list-item,
    .custom-list-header {
        min-width: 700px;
        margin-left: 0 !important;
    }

    .room-management-filters-options {
        display: flex;
        justify-content: space-between;
        width: 100%;
        position: relative;

        .room-management-mobile-filters {
            display: flex;
            align-items: center;
            // div{
            //     &:first-child{
            //         padding: 8px 14px;
            //     }
            // }
            svg {
                margin-right: 8px;
            }
        }

        .room-mobile-options {
            position: absolute;
            top: 40px;
            right: 0;
            background-color: white;
            z-index: 1000;
            border: 1px solid $neutralColor100;
            border-radius: 4px;
            div {
                margin-top: 4px;
            }
        }

        .mobile-link {
            margin: 8px !important;
            padding: 8px 16px 8px 0;
        }
    }
    .search-and-page-controls {
        padding-left: 0 !important;
        .button-primary {
            margin-right: 0 !important;
        }
    }

    .hidden-on-mobile {
        display: none;
        visibility: hidden;
        width: 0 !important;
        height: 0 !important;
        max-width: 0 !important;
        max-height: 0 !important;
        overflow: hidden;
    }

    .full-width-overflowX {
        flex: 1;
    }

    .page-title {
        padding-left: 0 !important;
    }

    .hardware-rooms {
        flex-direction: column;
    }

    .breadcrumb-wrapper {
        margin-top: -24px !important;
    }

    .condo-reservations{
        width: 100% !important;
    }

    .condo-occupancy{
        width: 100% !important;
    }

    .mobile-flex-column{
        flex-direction: column;
    }

    .display-mobile{
        display: block;
        height: 100px;
    }

    .mobile-noborder{
        border-left: 0 !important;
    }
}

@media only screen and (min-width: 600px) {
    #hamburger-mobile,
    .room-management-mobile-filters {
        display: none;
        visibility: hidden;
    }

    .hidden-big-screen {
        display: none;
        visibility: hidden;
        width: 0 !important;
        height: 0 !important;
        max-width: 0 !important;
        max-height: 0 !important;
        overflow: hidden;
    }
}
