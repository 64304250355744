.icons ul {
    list-style-type: none;
  }
  
  .icons li {
    display: inline-block;
  }
  
  .icons input[type="checkbox"][id^="cb"] {
    display: none;
  }
  
  .icons label {
    border: 1px solid #fff;
    padding: 10px;
    display: block;
    position: relative;
    margin: 5px;
    cursor: pointer;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  
  .icons label::before {
    background-color: white;
    color: white;
    content: " ";
    display: block;
    border-radius: 50%;
    border: 1px solid grey;
    position: absolute;
    top: -5px;
    left: -5px;
    width: 25px;
    height: 25px;
    text-align: center;
    line-height: 28px;
    transition-duration: 0.4s;
    transform: scale(0);
  }
  
  .icons label img {
    height:30px;
    width: 30px;
    transition-duration: 0.2s;
    transform-origin: 50% 50%;
  }
  
  .icons :checked+label {
    border-color: #ddd;
  }
  
  .icons :checked+label::before {
    content: "✓";
    background-color: grey;
    transform: scale(1);
  }

  .icons :checked+label img {
    transform: scale(0.9);
    box-shadow: 0 0 5px #333;
    z-index: -1;
  }