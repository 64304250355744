.inputfile {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
}

.inputfile + label {
    padding: 0.5rem;
    font-size: 0.7em;
    font-weight: 700;
    color: white;
    background-color: #d3394c;
    display: inline-block;
    cursor: pointer; /* "hand" cursor */
    /*keyboard nav*/
}

.input-wrapper .inputfile + label {
    padding: 0.5rem;
    font-size: 0.7em;
    font-weight: 700;
    display: inline-block;
    cursor: pointer; /* "hand" cursor */
    color: #0545a6;
    background-color: #ffffff;
    /*keyboard nav*/
}

.inputfile:focus + label,
.inputfile + label:hover {
    background-color: #4b0f31;
}

.inputfile + label * {
    pointer-events: none;
}

.input-wrapper .inputfile:focus + label,
.inputfile + label:hover {
    background-color: white;
    color: white;
}


.inputfile-v-2 {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
}

.inputfile-v-2 + label {
    padding: 0.5rem;
    font-size: 0.7em;
    font-weight: 700;
    color: #ffffff;
    background-color: #1068ec;
    display: inline-block;
    cursor: pointer; /* "hand" cursor */
    /*keyboard nav*/
}

.input-wrapper .inputfile-v-2 + label {
    padding: 0.5rem;
    font-size: 0.7em;
    font-weight: 700;
    display: inline-block;
    cursor: pointer; /* "hand" cursor */
    color: #ffffff;
    background-color: #0545a6;
    /*keyboard nav*/
}

.inputfile-v-2:focus + label,
.inputfile-v-2 + label:hover {
    background-color: #0545a6;
}

.inputfile-v-2 + label * {
    pointer-events: none;
}

.input-wrapper .inputfile-v-2:focus + label,
.inputfile-v-2 + label:hover {
    background-color: #0545a6;
    color: #ffffff;
}
