$primaryColor: #1068ec;
$primaryColor50: #dbe8fc;
$primaryColor100: #abcaf8;
$primaryColor500: #1068ec;
$sucessColor: #17e15c;
$warningColor: #fc8518;
$errorColor: #ec1010;
$UI-900: #00112b;
$UI-50: #ffffff;
$primaryColor900: #002459;
$neutralColor700: #69778c;
$neutralColor500: #8f9db2;
$neutralColor300: #a3aebf;
$neutralColor100: #d8e0ec;
$neutralColor50: #f3f6fb;
$standardFontSize: 12px;
$sideMenuUserDataWrapperHeight: 100px;
$sideMenuBackgroundColor: $neutralColor50;
$sideMenuLogoHeight: 78px;
$primaryColor700: #0545a6;
$primaryColor300: #5895f2;
$warningColor500: #fc8518;
$warningColor100: #fed4ae;
$warningColor50:#FFEDDC;
$errorColor500: #ec1010;
$errorColor50:#FCDBDB;
$sucessColor50: #DCFBE7;
$sucessColor900: #009934;

@mixin bold-letters {
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
}

@mixin button-common {
    // height: 32px;
    // all: unset;
    border: 2px solid transparent;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 6px 10px;
    border-radius: 4px;
    user-select: none;
    // outline: 1px solid $primaryColor500;
    // outline: none !important;
    &:hover {
        cursor: pointer;
    }
    @include bold-letters();
    span {
        @include bold-letters();
    }
    &:active {
        // border:1px solid transparent ;
        // border:1px solid transparent;
        // outline: none;
        border: 1px solid transparent;
        // border-color: inherit;
        // margin: 1px;
        // border:2px solid transparent;
    }
}

@mixin button-w-icon-common {
    padding: 0;
    span {
        padding-right: 12px;
    }
}

@mixin button-light-common {
    @include button-common();

    color: $primaryColor500;
    background-color: $primaryColor50;
    svg path {
        fill: $primaryColor500;
    }
    &:hover {
        color: $UI-50;
        background-color: $primaryColor500;
        svg path {
            fill: $UI-50;
        }
    }

    &:focus
    {
        color: $primaryColor500;
        background-color: $primaryColor50;
        outline: 2px solid transparent;
        // outline: 2px solid $primaryColor900;

        svg path {
            fill: $primaryColor500;
        }
    }
    &:focus-visible {
        color: $primaryColor500;
        background-color: $primaryColor50;
        // outline: 2px solid transparent;
        outline: 2px solid $primaryColor900;

        svg path {
            fill: $primaryColor500;
        }
    }

    &:disabled {
        color: $neutralColor700;
        background-color: $neutralColor100;
        svg path {
            fill: $neutralColor700;
        }
    }
    &:active {
    }
}

@mixin button-primary-common {
    @include button-common();

    color: $UI-50;
    background-color: $primaryColor500;
    svg path {
        fill: $UI-50;
    }
    &:hover {
        color: $UI-50;
        background-color: $primaryColor700;
        svg path {
            fill: $UI-50;
        }
    }

    &:focus{
        color: $UI-50;
        background-color: $primaryColor500;
        // outline: 2px solid $primaryColor900;
        outline: 2px solid transparent;
        svg path {
            fill: $UI-50;
        }
    }
    &:focus-visible {
        color: $UI-50;
        background-color: $primaryColor500;
        outline: 2px solid $primaryColor900;
        // outline: 2px solid transparent;
        svg path {
            fill: $UI-50;
        }
    }

    &:disabled {
        color: $UI-50;
        background-color: $neutralColor300;
        svg path {
            fill: $UI-50;
        }
    }
}

.button-light-w-icon {
    @include button-light-common();
    @include button-w-icon-common();
}

.button-light {
    @include button-light-common();
}
.button-light-active {
    color: $UI-50;
    background-color: $primaryColor500;
    &:hover {
        color: $UI-50;
        background-color: $primaryColor900;
    }
}
.button-light-w-icon-active {
    color: $UI-50;
    background-color: $primaryColor500;
    svg path {
        fill: $UI-50;
    }
    &:hover {
        color: $UI-50;
        background-color: $primaryColor900;
        svg path {
            fill: $UI-50;
        }
    }
}

.button-primary {
    @include button-primary-common();
}

.button-primary-w-icon {
    @include button-primary-common();
    @include button-w-icon-common();
}

@mixin button-white-common {
    @include button-common();
    color: $UI-900;
    svg path {
        fill: $UI-900;
    }
    &:hover {
        color: $UI-900;
        background-color: $neutralColor50;
        svg path {
            fill: $UI-900;
        }
    }

    &:focus,
    &:focus-visible {
        color: $UI-900;
        outline: 2px solid $primaryColor900;
        svg path {
            fill: $UI-900;
        }
    }

    &:disabled {
        color: $neutralColor700;
        svg path {
            fill: $neutralColor700;
        }
    }
}

.button-white {
    @include button-white-common();
}

.button-white-w-icon {
    @include button-white-common();
    @include button-w-icon-common();
}

.button-white-default {
    @include button-white-common();
    color: $UI-900;
    &:hover {
        color: $UI-900;
        background-color: $neutralColor50;
        svg path {
            fill: $UI-900;
        }
    }
}

.button-white-w-icon-only {
    @include button-common();
    @include button-w-icon-common();

    color: $UI-900;
    svg path {
        fill: $UI-900;
    }
    &:hover {
        color: $UI-900;
        background-color: $neutralColor50;
        svg path {
            fill: $UI-900;
        }
    }

    &:focus,
    &:focus-visible {
        color: $UI-900;
        outline: 2px solid $primaryColor900;
        svg path {
            fill: $UI-900;
        }
    }

    &:disabled {
        color: $neutralColor700;
        svg path {
            fill: $neutralColor700;
        }
    }
    &:active {
        outline: none;
        border: 2px solid transparent;
    }
}

.button-sidebar-w-icon-only {
    @include button-w-icon-common();
    border-bottom: 1px solid $primaryColor500;
    svg path {
        fill: $primaryColor500;
    }
}

.button-sidebar-dashboard-w-icon-only {
    @include button-w-icon-common();
    border-bottom: 1px solid $primaryColor500;
    svg path {
        stroke: $primaryColor500;
    }
}

.button-secondary {
    background-color: $neutralColor700;
    color: $UI-50;
    @include button-common();
    &:hover {
        color: $UI-50;
        background-color: $primaryColor900;
        svg path {
            fill: $UI-50;
        }
    }
    &:focus
    {
        color: $UI-50;
        background-color: $neutralColor700;
        // outline: 2px solid $primaryColor900;
        outline: 2px solid transparent;
        svg path {
            fill: $UI-50;
        }
    }
    &:focus-visible
     {
        color: $UI-50;
        background-color: $neutralColor700;
        outline: 2px solid $primaryColor900;
        // outline: 2px solid transparent;
        svg path {
            fill: $UI-50;
        }
    }

    &:disabled {
        color: $UI-50;
        background-color: $neutralColor300;
        svg path {
            fill: $UI-50;
        }
    }
}

.button-warning {
    background-color: $warningColor;
    color: $UI-50;
    @include button-common();
    &:disabled {
        color: $neutralColor700;
        background-color: $neutralColor100;
        svg path {
            fill: $neutralColor700;
        }
    }
}

.button-info {
    background-color: $primaryColor100;
    @include button-common();
}

.button-light {
    background-color: $neutralColor100;
    @include button-common();
}

.button-success {
    background-color: $sucessColor;
    @include button-common();
}

.button-danger {
    background-color: $errorColor;
    @include button-common();
    &:disabled {
        color: $neutralColor700;
        background-color: $neutralColor100;
        svg path {
            fill: $neutralColor700;
        }
    }
}

.button-primary-active {
    @include button-common();
    color: $UI-50;
    background-color: $primaryColor700;
    svg path {
        fill: $UI-50;
    }
    &:hover {
        color: $UI-50;
        background-color: $primaryColor700;
        svg path {
            fill: $UI-50;
        }
    }

    &:focus
    {
        color: $UI-50;
        background-color: $primaryColor700;
        // outline: 2px solid $primaryColor900;
        outline: 2px solid transparent;
        svg path {
            fill: $UI-50;
        }
    }
    &:focus-visible {
        color: $UI-50;
        background-color: $primaryColor700;
        outline: 2px solid $primaryColor900;
        // outline: 2px solid transparent;
        svg path {
            fill: $UI-50;
        }
    }

    &:disabled {
        color: $UI-50;
        background-color: $neutralColor300;
        svg path {
            fill: $UI-50;
        }
    }
}

@mixin button-red-common {
    @include button-common();

    color: $UI-50;
    background-color: #ED6A5A;
    svg path {
        fill: #ED6A5A;
    }
    &:hover {
        color: $UI-50;
        background-color: #c55c4e;
        svg path {
            fill: $UI-50;
        }
    }

    &:focus{
        color:$UI-50;
        background-color: $primaryColor50;
        outline: 2px solid transparent;
        // outline: 2px solid $primaryColor900;

        svg path {
            fill:#c55c4e;
        }
    }
    &:focus-visible {
        color:$UI-50;
        background-color: $primaryColor50;
        // outline: 2px solid transparent;
        outline: 2px solid $primaryColor900;

        svg path {
            fill:#c55c4e;
        }
    }

    &:disabled {
        color: $neutralColor700;
        background-color: $neutralColor100;
        svg path {
            fill: $neutralColor700;
        }
    }
    &:active {
    }
}
.button-red {
    @include button-red-common();
}

.button-red-active {
    color: white;
    background-color: #ED6A5A;
    &:hover {
        color: white;
        background-color: #c55c4e;
    }
}

.button-light-pos-selected {
    @include button-light-common();
    color: $primaryColor500;
    font-weight: 900;

    background-color: $primaryColor50;
    svg path {
        fill: $primaryColor500;
    }
    &:hover {
        color: $primaryColor500;
        background-color: $primaryColor50;
        svg path {
            fill: $primaryColor500;
        }
    }

    &:focus,
    &:focus-visible {
        color: $primaryColor500;
        background-color: $primaryColor50;
        outline: 2px solid #f3f6fb;
        svg path {
            fill: $primaryColor500;
        }
    }

    &:disabled {
        color: $neutralColor700;
        background-color: $neutralColor100;
        svg path {
            fill: $neutralColor700;
        }
    }
    &:active {
    }

}

.button-light-pos-not-selected {
    @include button-light-common();
    color: $UI-900;
    font-weight: 500;
    background-color: $neutralColor50;
    svg path {
        fill: $neutralColor50;
    }
    &:hover {
        color: $primaryColor500;
        background-color: $primaryColor50;
        svg path {
            fill: $primaryColor500;
        }
    }

    &:focus,
    &:focus-visible {
        color: $primaryColor500;
        background-color: $primaryColor50;
        outline: 2px solid #f3f6fb;
        svg path {
            fill: $primaryColor500;
        }
    }

    &:disabled {
        color: $neutralColor700;
        background-color: $neutralColor100;
        svg path {
            fill: $neutralColor700;
        }
    }
    &:active {
    }

}
.button-light-selected {
    @include button-light-common();
    color: $primaryColor500;
    font-weight: 900;

    background-color: $primaryColor50;
    svg path {
        fill: $primaryColor500;
    }
    &:hover {
        color: $primaryColor500;
        background-color: $primaryColor50;
        svg path {
            fill: $primaryColor500;
        }
    }

    &:focus,
    &:focus-visible {
        color: $primaryColor500;
        background-color: $primaryColor50;
        outline: 2px solid #f3f6fb;
        svg path {
            fill: $primaryColor500;
        }
    }

    &:disabled {
        color: $neutralColor700;
        background-color: $neutralColor100;
        svg path {
            fill: $neutralColor700;
        }
    }
    &:active {
    }

}

.button-light-not-selected {
    @include button-light-common();
    color: $UI-900;
    font-weight: 500;
    background-color: $neutralColor50;
    svg path {
        fill: $neutralColor50;
    }
    &:hover {
        color: $primaryColor500;
        background-color: $primaryColor50;
        svg path {
            fill: $primaryColor500;
        }
    }

    &:focus,
    &:focus-visible {
        color: $primaryColor500;
        background-color: $primaryColor50;
        outline: 2px solid #f3f6fb;
        svg path {
            fill: $primaryColor500;
        }
    }

    &:disabled {
        color: $neutralColor700;
        background-color: $neutralColor100;
        svg path {
            fill: $neutralColor700;
        }
    }
    &:active {
    }

}
.button-white-only {
    @include button-white-common();
    background-color: $UI-50;
    color: $UI-900;
    &:hover {
        color: $UI-900;
        background-color: $neutralColor50;
        svg path {
            fill: $UI-900;
        }
    }
}

.documents-view{

    select {
        -webkit-appearance:none;
        -moz-appearance:none;
        -ms-appearance:none;
        appearance:none;
        outline:0;
        box-shadow:none;
        border:0!important;
        // background: #1068ec;
        background: #f3f6fb;
        color: $primaryColor500;
        background-image: none;
        flex: 1;
        padding: 0 .5em;
        // color:#fff;
        cursor:pointer;
        font-size: 1em;
        font-family: 'Open Sans', sans-serif;
      
    
     }
     select::-ms-expand {
        display: none;
     }
     .select {
        position: relative;
        display: flex;
        width: 20em;
        height: 3em;
        line-height: 3;
        background: #5c6664;
        overflow: hidden;
        border-radius: .25em;
        margin-top: 24px;
        height: 32px;
        display: flex;
        align-items: center;
     }
     .select::after {
        content: '\25BC';
        position: absolute;
        top: 0;
        right: 0;
        padding: 0 1em;
        background: $neutralColor100;
        cursor:pointer;
        pointer-events:none;
        transition:.25s all ease;
        color: $neutralColor700;
        height: 32px;
        height: 32px;
        display: flex;
        align-items: center;
     }
     .select:hover::after {
        color: $primaryColor;
     }
}

.button-white-selected {
    @include button-white-common();
    color: $primaryColor500;
    font-weight: 500;

    background-color: $primaryColor50;
    svg path {
        fill: $primaryColor500;
    }
    &:hover {
        // color: $primaryColor500;
        // background-color: $UI-50;
        // svg path {
        //     fill: $primaryColor500;
        // }
    }

    &:focus,
    &:focus-visible {
        color: $primaryColor500;
        background-color: $primaryColor50;
        outline: 2px solid #f3f6fb;
        svg path {
            fill: $primaryColor500;
        }
    }

    &:disabled {
        color: $neutralColor700;
        background-color: $neutralColor100;
        svg path {
            fill: $neutralColor700;
        }
    }
    &:active {
    }

}

.button-white-not-selected {
    @include button-white-common();
    color: $UI-900;
    font-weight: 500;
    background-color: $UI-50;
    svg path {
        fill: $neutralColor50;
    }
    &:hover {
        // color: $primaryColor500;
        // background-color: $primaryColor100;
        // svg path {
        //     fill: $primaryColor500;
        // }
    }

    &:focus,
    &:focus-visible {
        // color: $primaryColor500;
        // background-color: $primaryColor100;
        // outline: 2px solid #f3f6fb;
        // svg path {
        //     fill: $primaryColor500;
        // }
    }

    &:disabled {
        color: $neutralColor700;
        background-color: $neutralColor100;
        svg path {
            fill: $neutralColor700;
        }
    }
    &:active {
    }

}

.button-neutral-selected {
    @include button-white-common();
    color: $primaryColor500;
    font-weight: 500;

    background-color: $primaryColor50;
    svg path {
        fill: $primaryColor500;
    }
    &:hover {
        // color: $primaryColor500;
        // background-color: $UI-50;
        // svg path {
        //     fill: $primaryColor500;
        // }
    }

    &:focus,
    &:focus-visible {
        color: $primaryColor500;
        background-color: $primaryColor50;
        outline: 2px solid #f3f6fb;
        svg path {
            fill: $primaryColor500;
        }
    }

    &:disabled {
        color: $neutralColor700;
        background-color: $neutralColor100;
        svg path {
            fill: $neutralColor700;
        }
    }
    &:active {
    }

}

.button-neutral-not-selected {
    @include button-white-common();
    color: $UI-900;
    font-weight: 500;
    background-color: $neutralColor50;
    svg path {
        fill: $neutralColor50;
    }
    &:hover {
        // color: $primaryColor500;
        // background-color: $primaryColor100;
        // svg path {
        //     fill: $primaryColor500;
        // }
    }

    &:focus,
    &:focus-visible {
        // color: $primaryColor500;
        // background-color: $primaryColor100;
        // outline: 2px solid #f3f6fb;
        // svg path {
        //     fill: $primaryColor500;
        // }
    }

    &:disabled {
        color: $neutralColor700;
        background-color: $neutralColor100;
        svg path {
            fill: $neutralColor700;
        }
    }
    &:active {
    }

}

.button-arrival {
    @include button-common();
    color: $UI-50;
    background-color: $primaryColor300;
    // &:hover {
    //     color: $UI-50;
    //     background-color: $UI-900;
    //     svg path {
    //         fill: $UI-50;
    //     }
    // }
}

.button-due-out {
    @include button-common();

    color: $warningColor500;
    background-color: $warningColor100;
    // &:hover {
    //     color: $UI-50;
    //     background-color: $UI-900;
    //     svg path {
    //         fill: $UI-50;
    //     }
    // }

}


.button-stay {
    @include button-common();

    color: $sucessColor900;
    background-color: $sucessColor50;
    // &:hover {
    //     color: $UI-50;
    //     background-color: $UI-900;
    //     svg path {
    //         fill: $UI-50;
    //     }
    // }

}



.button-checked-out {
    @include button-common();

    color: $UI-50;
    background-color: $neutralColor700;
    // &:hover {
    //     color: $UI-50;
    //     background-color: $UI-900;
    //     svg path {
    //         fill: $UI-50;
    //     }
    // }

}
.button-checked-in {
    @include button-common();

    color: $sucessColor50;
    background-color: $sucessColor900;
    // &:hover {
    //     color: $UI-50;
    //     background-color: $UI-900;
    //     svg path {
    //         fill: $UI-50;
    //     }
    // }

}
.button-empty {
    @include button-common();

    color: $errorColor500;
    background-color: $errorColor50;
    // &:hover {
    //     color: $UI-50;
    //     background-color: $UI-900;
    //     svg path {
    //         fill: $UI-50;
    //     }
    // }

}

.button-all{
    @include button-common();

    color: $UI-900;
    background-color: $primaryColor50;
    // &:hover {
    //     color: $UI-50;
    //     background-color: $UI-900;
    //     svg path {
    //         fill: $UI-50;
    //     }
    // }
}

.button-status-selected{
    @include button-common();

    color: $UI-50;
    background-color: $UI-900;
    // &:hover {
    //     color: $UI-900;
    //     background-color: $UI-50;
    //     svg path {
    //         fill: $UI-900;
    //     }
    // }
}

@media (max-width: 820px) {
    .button-white-selected {
        width: 98%;
    }
    .button-white-not-selected {
        width: 98%;
    }
    .button-neutral-selected {
        width: 98%;
    }
    .button-neutral-not-selected {
        width: 98%;
    }
    .button-status-selected {
        width: 98%;
    }
    .button-all {
        width: 98%;
    }
    .button-empty {
        width: 98%;
    }
}

.button-white-w-icon-only-selected {
    @include button-common();
    @include button-w-icon-common();
    background-color: $neutralColor50;
    color: $UI-900;
    svg path {
        fill: $UI-900;
    }
    &:hover {
        color: $UI-900;
        background-color: $neutralColor50;
        svg path {
            fill: $UI-900;
        }
    }

    &:focus,
    &:focus-visible {
        color: $UI-900;
        outline: 2px solid $primaryColor900;
        svg path {
            fill: $UI-900;
        }
    }

    &:disabled {
        color: $neutralColor700;
        svg path {
            fill: $neutralColor700;
        }
    }
    &:active {
        outline: none;
        border: 2px solid transparent;
    }
}
