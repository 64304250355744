$primaryColor: #1068ec;
$primaryColor50: #dbe8fc;
$primaryColor100: #abcaf8;
$primaryColor500: #1068ec;
$sucessColor: #17e15c;
$warningColor: #fc8518;
$errorColor: #ec1010;
$UI-900: #00112b;
$UI-50: #ffffff;
$primaryColor900: #002459;
$neutralColor700: #69778c;
$neutralColor500: #8f9db2;
$neutralColor300: #a3aebf;
$neutralColor100: #d8e0ec;
$neutralColor50: #f3f6fb;
$standardFontSize: 12px;
$sideMenuUserDataWrapperHeight: 100px;
$sideMenuBackgroundColor: $neutralColor50;
$sideMenuLogoHeight: 78px;
$primaryColor700: #0545a6;

.custom-select-wrapper {
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    user-select: none;
    position: relative;
    cursor: pointer;
    width: 200px;
    border: 1px solid $neutralColor100;
    border-radius: 6px;
    // height: 32px;
    #search-string {
        display: inline-block;
        border: none;
        outline: none;
        padding-left: 4px;
        width: 100%;
    }
    .custom-select-flex {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .options-wrapper {
        position: absolute;
        text-align: left;
        z-index: 12121;
        margin-top: 5px;
        width: 100%;
        border: 1px solid lightgray;
        background-color: $neutralColor50;
    }
    // background: #FFFFFF;
    // border: 1px solid $neutralColor100;
    // border-radius: 4px;
    // height: 32px;
    // width: 187px;
    // padding: 8px 16px;

    .option {
        padding: 8px 12px;
        width: 100%;
        &:hover {
            background-color: $neutralColor100;
        }
    }

    &:hover {
        border: 1px solid $neutralColor300;
    }

    &:active,
    &:focus-within {
        border: 1px solid $primaryColor500;
    }

    &:focus,
    &:focus-visible {
        border: 1px solid $UI-900;
        outline: none;
    }

    &:disabled {
        border: 1px solid $neutralColor50;
        background-color: $neutralColor50;
    }

    .custom-select-selected-items-wrapper {
        display: flex;
        align-items: center;
        width: 100%;
        flex-wrap: wrap;
    }

    .selected-item {
        display: flex;
        align-items: center;
        padding: 1px;
        background-color: $neutralColor100;
        margin: 2px;
        border-radius: 4px;
        i {
            padding: 1px 4px;
            &:hover {
                background-color: $neutralColor300;
            }
        }
    }
}

.custom-select-wrapper.disabled {
    border: 1px solid $neutralColor50;
    background-color: $neutralColor50;
}

.options-wrapper {
    position: absolute;
    text-align: left;
    z-index: 12121;
    margin-top: 5px;
    width: 100%;
    border: 1px solid lightgray;
    background-color: $neutralColor50;
    cursor: pointer;
    // min-height: 50px;
    min-height: 32px;
    max-height: 250px;
    overflow-y: scroll;
    overflow-x: hidden;
}

.option {
    padding: 8px 12px;
    width: 100%;
    cursor: pointer;
    &:hover {
        background-color: $neutralColor100;
    }
}
.custom-split-button-select {
    height: 32px;
    max-height: 32px;
    position: relative;
    display: inline-block;
    width: 100%;
    height: calc(1.5em + 0.75rem + 2px);
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    vertical-align: middle;
    // background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px;
    // background-color: #fff;
    background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='%231068ec' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e")
        no-repeat right 0.75rem center/8px 10px;

    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    appearance: none;
    width: 300px;
    cursor: pointer;
    background-color: $primaryColor50;
    color: $primaryColor500;
    font-size: 12px;
    line-height: 16px;

    .select-header {
        padding: 6px 10px;
        font-size: 12px;
        user-select: none;
        font-weight: 600;
    }
}

.custom-split-button-options-wrapper {
    position: absolute;
    top: 32px;
    left: 0;
    background: white;
    z-index: 99999;
    border: 1px solid lightgrey;
    width: 300px;
}

.custom-split-button-option {
    border-bottom: 1px solid lightgrey;
    height: 32px;
    vertical-align: middle;
    cursor: pointer;
    display: flex;
    align-items: center;
    padding: 6px 10px;

    &:last-child {
        border-bottom: 0;
    }

    &:hover {
        background-color: $neutralColor100;
    }
}

.multipleControllersWrapper{
    text-align: center;
    margin-bottom: 2px;
}

.multipleControllers{
    background-color: $primaryColor50;
    color: $primaryColor500;
    font-size: 16px;
    line-height: 16px;
    width: 300px;
    text-align: center;
    padding: 5px;
    font-weight: 400;
    border: 1px solid lightgrey;
    border-radius: 0.25rem;
    display: inline-block;
}
