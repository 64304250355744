/* Styles go here */

/* .page-header, .page-header-space {
  height: 100px;
} */

/* .page-footer, .page-footer-space {
  height: 50px;

} */

.journal-footer {
  position: fixed;
  bottom: 0;
  z-index: 2222;
 /* background-color: white; */
  min-width: 600px;

}

.journal-header {
  position: fixed;  
  z-index: 2222;
  background-color: white;
  min-width: 600px;
  width: 100% !important;
  overflow-x: auto; 
}

.journal { 
  page-break-after: always;

}

@page {
  margin: 20mm

}

.printOnly {
 display: none;
}
@media screen {
  thead {display: table-header-group;} 
   tfoot {display: table-footer-group;}
}

@media print {
   thead {display: table-header-group;} 
   tfoot {display: table-footer-group;}
   .printOnly {
    display: block;
   }
   button {display: none;}
   
   body {margin: 0;}
}