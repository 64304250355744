$theme-colors: (
  "primary": #1991eb,
  "light":#F2F7F8,
  "secondary": #6c757d,
  "success":#58d8a3,
  "warning": #ffc105,
  "white":#fff
);

.card .card-header {
  background-color: white; 
  
}
.card .card-footer {
  background:linear-gradient(88deg,#04befe,#318be3); 
  border-color: #F2F7F8;
  
}

$enable-gradients: true;

@import "../node_modules/bootstrap/scss/bootstrap";


