.rt-layout {
  margin-left: -($react-timelines-sidebar-width - $react-timelines-sidebar-closed-offset);

  @media (min-width: $react-timelines-auto-open-breakpoint) {
    margin-left: 0;
  }

  &.rt-is-open {
    margin-left: 0;
  }
}

.rt-layout__side {
  position: relative;
  z-index: 2;
  display: inline-block;
  width: $react-timelines-sidebar-width;
  vertical-align: top;
}
@media only screen and (max-width: 600px) {
  .rt-layout__side {
    width: 100px;
  }

.rt-layout__main {
  display: inline-block;
  width: calc(100% - 100px) !important;
  vertical-align: top;
}
}

.rt-layout__main {
  display: inline-block;
  width: calc(100% - #{$react-timelines-sidebar-width});
  vertical-align: top;
}

.rt-layout__timeline {
  overflow-x: auto;
}
