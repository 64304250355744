/* Styles go here */

/* .page-header, .page-header-space {
  height: 100px;
} */

/* .page-footer, .page-footer-space {
  height: 50px;

} */

.reservation-details-footer {
    position: fixed;
    bottom: 0;
    /* width: 900px; */
    text-align: center;
    z-index: 2222;
}

.reservation-details-header {
    position: fixed;
    text-align: center;
    /* width: 900px; */

    z-index: 2222;
}

.reservation-details {
    page-break-after: always;
}

@page {
    margin: 20mm;
}

.printOnly {
    display: none;
}
@media screen {
    thead {
        display: table-header-group;
    }
    tfoot {
        display: table-footer-group;
    }
}

@media print {
    thead {
        display: table-header-group;
    }
    tfoot {
        display: table-footer-group;
    }
    .printOnly {
        display: block;
    }
    button {
        display: none;
    }

    .reservation-items {
      border-bottom: 0.5px solid !important;
      border-color: rgba(0, 0, 0, 0.452) !important;
    }

    body {
        margin: 0;
     
    }
}
