.rt-controls {
  display: inline-block;
  padding: 8px;
  margin: 0 0 $react-timelines-spacing 0;
  background-color: #fff;
}

.rt-controls__button {
  display: inline-block;
  width: $react-timelines-button-size;
  height: $react-timelines-button-size;
  overflow: hidden;
  background-color: $react-timelines-button-background-color;
  color: transparent;
  white-space: nowrap;
  padding: $react-timelines-spacing;
  outline: none;

  &:last-child {
    margin-right: 0;
  }

  &:hover {
    background-color: $react-timelines-button-background-color-hover;
  }

  &:focus,
  &:active {
    background-color: $react-timelines-button-background-color-hover;
  }
}

.rt-controls__button[disabled] {
  opacity: 0.5;
}

.rt-controls__button--toggle {
  @media (min-width: $react-timelines-auto-open-breakpoint) {
    display: none;
  }
}
